import React from 'react'
import "./DMBanner.css";
function DMBanner() {
  return (
    <div className='container-fluid sapbannerpart' id='DMBanner'>
        <div className='row p-sm-0 p-md-5 sapbannertext'>
<h1 className='dmheadertext'>Elevating performance marketing
with our data-driven
Digital Marketing Intuition</h1>
<p className='dmpara'>Consistent outcomes, innovative Strategies</p>

        </div>
    </div>
  )
}

export default DMBanner


